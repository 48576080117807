
import { ref, defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

interface ReservationDetails {
  //reservaId: string;
  estacionRetiro: string;
  estacionDevolucion: string;
  esRetiroPersonalizado:boolean|undefined,
  esDevolucionPersonalizada:boolean|undefined,
  direccionRetiro: string;
  direccionDevolucion: string;
  comunaRetiro: string;
  comunaDevolucion: string;
  fechaRetiro: Date | undefined;
  fechaDevolucion: Date | undefined;
  horaRetiro: Date | undefined;
  horaDevolucion: Date | undefined;
  rut: string;
  nombre: string;
  telefono: string;
  email: string;
  clientId: number;
  driverId: number;
}

export default defineComponent({
  name: "dashboard",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
    const loading = ref(true);
    const crearViaje = () => {
      const reservationDetails = ref<ReservationDetails>({
          estacionRetiro: "",
          estacionDevolucion: "",
          direccionRetiro: "",
          direccionDevolucion: "",
          comunaRetiro: "",
          comunaDevolucion: "",
          esRetiroPersonalizado:false,
          esDevolucionPersonalizada:false,
          fechaRetiro: moment().add(1, 'days').toDate(),
          fechaDevolucion: moment().add(2, 'days').toDate(),
          horaRetiro: moment("20111031 12:00:00", "YYYYMMDD hh:mm:ss").toDate(),
          horaDevolucion: moment("20111031 12:00:00", "YYYYMMDD hh:mm:ss").toDate(),
          nombre: `${currentUser.value.given_name} ${currentUser.value.family_name}`,
          email: currentUser.value.email,
          telefono: currentUser.value.phone_number,
          rut: currentUser.value.driverId.toString(),
          clientId: currentUser.value?.clientId,
          driverId: currentUser.value?.driverId,
        });
      store
          .dispatch(Actions.INS_RESERVA_COMPARTIDA, reservationDetails.value)
          .then(() => {
            store.commit(
              Mutations.SET_RESERVA_ID,
              store.getters.currentReserva.reservaId
            );
            router.push({ name: "shared-reservation-personal" });
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getReservaErrors);
            Swal.fire({
              text: store.getters.getReservaErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Intentar nuevamente!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    };
    return {
      loading, crearViaje
    };
  },
});
